import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

Vue.config.productionTip = false

Vue.prototype.$api = "https://dealerapi.jcwebprod.com"
Vue.prototype.$dealer = "https://dealer.jcwebprod.com/"

var $vm = new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
