<template>
	<div class="update">
		<div class="title centered">
			Information
		</div>

		<div class="information">
			<div class="title-small">
				Basic Usage:
			</div>  

			<v-divider />
			<ul>
				<li><b>There are different functions under the page banner you can select from (Serial Search, Parts Search, Documents, Links, Dealer Portal)</b></li>
				<li><b>Serial Search Instructions:</b></li>
				<ul>
					<li>Make sure you are on the Serial Search tab.</li>
					<li>On the top left you can enter a serial number in and click search or hit the enter key. Serials must be complete for the search to work. Curently most units older than 2014 and newer than 2023 don't work.</li>
					<li>Once a serial number has been successfully searched, you will see a basic serial information breakdown on the top right.</li>
					<li>Below the search area you will find a list of categories when you successfully load a unit. You can expand these categories by clicking on them.</li>
					<ul>
						<li>If a part number has an image associated with it, you will be able to see an image preview if you hover over it. This doesn't currently work on the supersede</li>
						<li>If a number is red, that means it is generally unpurchasable. In these cases there will usually be a supersede or it is there for informational purposes only.</li>
						<li>If a number has a <v-icon>mdi-arrow-right-drop-circle</v-icon> icon, you can get a list of sub-parts by clicking on it.</li>
						<ul>If a number has a <b>➾</b> and then a number, the number after the ➾ is the newer version.</ul>
					</ul>
					<li>Once you get to the part you want, you can copy the part number and paste it into CADshare's Parts Search functionality to get pricing and availability. 
						Unfortunately there is currently no tie in between this serial number tool and CADshare. We are hoping to make this process easier in the future.</li>
					<li><span style="color: red;"><b>If something seems off about the information (Saying 12T in the description when looking at a 6T, Conflicting top level assemblies, etc.) 
						feel free to call or E-mail. This tool is still a work in progress and there were errors in the data that we are working to correct.</b></span></li>
				</ul>
				<li><b>Part Search Instructions:</b></li>
				<ul>
					<li>Make sure you are on the Part Search tab.</li>
					<li>Type a part number into the search box on the top right and click search or hit enter. Partial searches are allowed.</li>
					<li>If the search has multiple results a window will pop up asking you to select which part you were looking for. Find the one you want and click on it.</li>
					<li>Image, basic part information, notes and sub-components will be displayed if available.</li>
					<li><span style="color: red;"><b>Not all parts are in the system. We are adding what we think are the more commonly used parts and their sub-components first.</b></span></li>
				</ul>
			</ul><br>

			<span class="title-small">
				<b>New in version {{ this.version }}:&nbsp;</b>
			</span>

			<span>* Please report bugs or issues to <a href src="mailto:parts@supremeinternational.com">parts@supremeinternational.com</a></span><br>

			<v-divider />

			<ul>
				<li>Added categories for Hydraulics and Manuals so that they weren;t always sitting in "Other".</li>
				<li>Added a sub-category system that will key in on certain parts and automatically group them under a sub-category to reduce list length. Examples of this are Fittings and Hardware. If it detects a bolt number as a sub-component it will automatically sort it into the Hardware sub-category.</li>
			</ul><br />

			<div class="title-small">
				<b>New in version 1.8.2:</b>
			</div>

			<v-divider />

			<ul>
				<li>Parts notes will now display in both the serial number search and part number search.</li>
				<li>Parts notes show in the image pop up and in the description box of any part that has a note associated.</li>
			</ul><br />

			<div class="title-small">
				<b>New in version 1.8.0:</b>
			</div>

			<v-divider />

			<ul>
				<li>Added tabs for "Documents", "Links" and "Dealer Portal". Documents and Links will come in future update.</li>
				<li>Added the ability to do part searches.</li>
				<li>Added the Supreme Dealer Portal to a tab</li>
				<li>Changed the banner at the top to better match Supreme's branding for a more professional look.</li>
				<li>Fixed various bugs.</li>
			</ul><br />

			<div class="title-small">
				<b>New in version 1.7.2:</b>
			</div>

			<v-divider />

			<ul>
				<li>Added ability to see pictures by hovering over part number.</li>
				<li>Added a Copy Link button that will copy the web address for that specific unit so it can be shared.</li>
				<li>Added a Help button which will bring this information screen up at any time.</li>
				<li>Added smoother transition when expanding parts trees.</li>
				<li>Fixed a multiple search bug where if you hit enter twice or clicked the button twice quickly it would give dupliacted results under the categories.</li>
				<li>Added ability to serial numbers in the address bar. You would send someone by:</li>
				<ul>
					<li>Searching a unit serial number and if the search is successful clicking the "Copy Link" button.</li>
				</ul>
				<li>Added a Second Cutter category for units that need it.</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { version } from '../../package.json'

export default {
	name: 'Update',
	components: {
	},
	data() {
		return {
			version: version,
		}
	},
	props: {
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.update {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-color: white;
	border: 2px solid darkgrey;
	border-radius: 5px;
}

.centered {
	text-align: center;
}

.title {
	font-size: 24pt;
	border: 1px solid darkgrey;
	background-color: lightblue;
	padding: 5px;
	width: 100%;
}

.title-small {
	width: 100%;
	font-size: 16pt;
	text-align: left;
}

.information {
	flex-grow: 1;
	padding: 5px;
	width: 100%;
	border: 1px solid darkgrey;
	overflow-y: scroll;
	overflow-x: hidden;
	text-align: left;
}
</style>