<template>
    <div class="documents">
        <v-spacer /> Coming Soon! <v-spacer />
    </div>
</template>

<script>
export default {
    name: 'Documents',
    components: {
    },
    props: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.documents {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 82pt;
}
</style>